














































import { defineComponent } from '@vue/composition-api';
import Banner from '@/views/Home/Banner.vue';

export default defineComponent({
  components: { Banner },
  setup() {
    return {};
  },
});
